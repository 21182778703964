.login-small-height {
    height:36.19px;
    /*margin-top:-0.4rem;*/
    /*margin-bottom:-0.4rem;*/
    /*margin-left:0.5rem;*/
    /*margin:0;*/
    width:100%;
}

.login-small-height > .p-button.p-button-icon-only {
    width:0.5rem;
    background:#999;
}

.login-small-height > .p-inputtext {
    margin-top:-0.3rem;
    text-align:left;
    padding-left: 1rem;
    /*padding-right: 0.25rem;*/
    /*background:#999;*/
}
