@import "primereact/resources/primereact.min.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";
@import "assets/css/app/layout.scss";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/css/demo/flags.css";

.pageWrapper {
  top: 0;
}

.pageWrapperWithTopBar {
  top: 80px;
}

.p-overlay-badge .p-badge {
  position:absolute;
  top: 5px;
  right: 5px;
  font-size: 0.65rem;
  min-width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  //z-index: 99999;
}